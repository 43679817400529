import ApiRepository from '@/repository/config/Api';
import type { AxiosPromise } from 'axios';
import type DocumentStatus from '@/enums/config/document/Status';
import type DownloadDocument from '@/enums/config/document/Download';
import type IAdvanceInvoiceLookupParams from '@/interfaces/forms/document/IAdvanceInvoiceLookupParams';
import type IDocumentBulkReportForm from '@/interfaces/forms/document/IDocumentBulkReportForm';
import type IDocumentPaymentForm from '@/interfaces/forms/document/IDocumentPaymentForm';
import type IDocumentReportForm from '@/interfaces/forms/document/IDocumentReportForm';
import type ISendDocumentForm from '@/interfaces/forms/email/ISendDocumentForm';
import type IUrlParams from '@/interfaces/IUrlParams';
import type IDocument from '@/interfaces/document/IDocument';
import type IRecurringDocument from '@/interfaces/document/IRecurringDocument';

export default class DocumentRepository {
  // List all documents
  public static list(companyUUID: string, params: IUrlParams): AxiosPromise {
    return ApiRepository.get('/companies/' + companyUUID + '/bookkeeping-records', { params });
  }

  // List all advanced invoices
  public static listAdvanceInvoices(companyUUID: string, params: IAdvanceInvoiceLookupParams): AxiosPromise {
    return ApiRepository.get('/companies/' + companyUUID + '/bookkeeping-records/find-advance-invoices', { params });
  }

  // List all document labels
  public static listLabels(companyUUID: string, params: IUrlParams): AxiosPromise {
    return ApiRepository.get('/companies/' + companyUUID + '/bookkeeping-records/labels', { params });
  }

  // Fetch single document
  public static fetch(companyUUID: string, documentUUID: string): AxiosPromise {
    return ApiRepository.get('/companies/' + companyUUID + '/bookkeeping-records/' + documentUUID);
  }

  // Create new document
  public static create(companyUUID: string, payload: IDocument | IRecurringDocument): AxiosPromise {
    return ApiRepository.post('/companies/' + companyUUID + '/bookkeeping-records', payload);
  }

  // Update existing document
  public static update(
    companyUUID: string,
    documentUUID: string,
    payload: IDocument | IRecurringDocument,
  ): AxiosPromise {
    return ApiRepository.put('/companies/' + companyUUID + '/bookkeeping-records/' + documentUUID, payload);
  }

  // Delete existing document
  public static delete(companyUUID: string, documentUUID: string): AxiosPromise {
    return ApiRepository.delete('/companies/' + companyUUID + '/bookkeeping-records/' + documentUUID);
  }

  // Create existing document link for sharing
  public static share(companyUUID: string, documentUUID: string): AxiosPromise {
    return ApiRepository.post('/companies/' + companyUUID + '/bookkeeping-records/' + documentUUID + '/generate-link');
  }

  // Generate and download single document in selected format - response type should be blob
  public static download(companyUUID: string, documentUUID: string, fileType: DownloadDocument): AxiosPromise {
    return ApiRepository.get('/companies/' + companyUUID + '/bookkeeping-records/' + documentUUID + '/' + fileType, {
      responseType: 'blob',
    });
  }

  // Generate next document number iterator
  public static recordNumber(companyUUID: string, number_format: string): AxiosPromise {
    return ApiRepository.get('/companies/' + companyUUID + '/bookkeeping-records/next-iterator', {
      params: { number_format },
    });
  }

  // Check record number availability
  public static checkRecordNumber(companyUUID: string, record_number: string): AxiosPromise {
    return ApiRepository.get('/companies/' + companyUUID + '/bookkeeping-records/check-number-availability', {
      params: { record_number },
    });
  }

  /*****        Document payment operations      *****/

  // List all documents payments
  public static listPayments(companyUUID: string, documentUUID: string): AxiosPromise {
    return ApiRepository.get('/companies/' + companyUUID + '/bookkeeping-records/' + documentUUID + '/payments');
  }

  // Create new document payment
  public static createPayment(companyUUID: string, documentUUID: string, payload: IDocumentPaymentForm): AxiosPromise {
    return ApiRepository.post(
      '/companies/' + companyUUID + '/bookkeeping-records/' + documentUUID + '/payments',
      payload,
    );
  }

  // Delete existing document payemnt
  public static deletePayment(companyUUID: string, documentUUID: string, documentPaymentUUID: string): AxiosPromise {
    return ApiRepository.delete(
      '/companies/' + companyUUID + '/bookkeeping-records/' + documentUUID + '/payments/' + documentPaymentUUID,
    );
  }

  /*****        Bulk operations      *****/

  // Change status to multiple documents
  public static bulkSetStatus(companyUUID: string, documents: string[], status: DocumentStatus): AxiosPromise {
    return ApiRepository.post('/companies/' + companyUUID + '/bookkeeping-records/bulk/change-status', {
      documents,
      status,
    });
  }

  // Delete multiple existing documents
  public static bulkDelete(companyUUID: string, documents: string[]): AxiosPromise {
    return ApiRepository.post('/companies/' + companyUUID + '/bookkeeping-records/bulk/delete', { documents });
  }

  // Send documents via email
  public static bulkSend(companyUUID: string, payload: ISendDocumentForm): AxiosPromise {
    return ApiRepository.post('/companies/' + companyUUID + '/bookkeeping-records/bulk/send', payload);
  }

  // Dowload overview report of slected documents
  public static overviewReport(
    companyUUID: string,
    payload: IDocumentReportForm | IDocumentBulkReportForm,
  ): AxiosPromise {
    return ApiRepository.post('/companies/' + companyUUID + '/bookkeeping-records/overview-export', payload, {
      responseType: 'blob',
    });
  }

  /*****        Bulk PDF export operations      *****/

  // Download multiple documents
  public static bulkDownload(companyUUID: string, documents: string[]): AxiosPromise {
    return ApiRepository.post('/companies/' + companyUUID + '/bookkeeping-records/bulk/export', { documents });
  }

  // Get zip job progress status of multiple document download
  public static bulkDownloadProgress(companyUUID: string): AxiosPromise {
    return ApiRepository.get('/companies/' + companyUUID + '/bookkeeping-records/bulk/get-job-status');
  }
}
