import { Component, Mixins } from 'vue-property-decorator';
import CatchError from '@/decorators/CatchError';
import Loading from '@/decorators/Loading';
import LoadingBulk from '@/decorators/LoadingBulk';
import CatchRule from '@/enums/config/CatchRule';
import FileType from '@/enums/config/FileType';
import AppState from '@/enums/generics/AppState';
import DownloadDocument from '@/enums/config/document/Download';
import DocumentType from '@/enums/config/document/Type';
import DocumentStatus from '@/enums/config/document/Status';
import DocumentRouteName from '@/enums/config/router/Document';
import type IDocument from '@/interfaces/document/IDocument';
import type IDocumentList from '@/interfaces/document/IDocumentList';
import type IDocumentPayment from '@/interfaces/document/IDocumentPayment';
import type IAdvanceInvoiceList from '@/interfaces/document/IAdvanceInvoiceList';
import type IDocumentPaymentForm from '@/interfaces/forms/document/IDocumentPaymentForm';
import type IAdvanceInvoiceLookupParams from '@/interfaces/forms/document/IAdvanceInvoiceLookupParams';
import type IDocumentBulkReportForm from '@/interfaces/forms/document/IDocumentBulkReportForm';
import type IDocumentReportForm from '@/interfaces/forms/document/IDocumentReportForm';
import type ISendDocumentForm from '@/interfaces/forms/email/ISendDocumentForm';
import type IDocumentMixin from '@/interfaces/mixins/IDocumentMixin';
import type IUrlParams from '@/interfaces/IUrlParams';
import CompanyModule from '@/store/modules/Company';
import DocumentModule from '@/store/modules/Document';
import PageModule from '@/store/modules/Page';
import CreateDocumentMixin from '@/mixins/document/Create';
import DeleteDocumentMixin from '@/mixins/document/Delete';
import DownloadDocumentMixin from '@/mixins/document/Download';
import FetchDocumentMixin from '@/mixins/document/Fetch';
import ListDocumentsMixin from '@/mixins/document/List';
import ListAdvanceInvoiceDocumentsMixin from '@/mixins/document/ListAdvanceInvoice';
import ListLabelsDocumentsMixin from '@/mixins/document/ListLabels';
import NextDocumentNumberSequenceMixin from '@/mixins/document/NextNumberSequence';
import CheckDocumentNumberAvailabilityMixin from '@/mixins/document/CheckNumberAvailability';
import PrintDocumentMixin from '@/mixins/document/Print';
import DocumentReportMixin from '@/mixins/document/Report';
import ShareDocumentMixin from '@/mixins/document/Share';
import UpdateDocumentMixin from '@/mixins/document/Update';
import BulkDeleteDocumentsMixin from '@/mixins/document/bulk/Delete';
import BulkDownloadDocumentsMixin from '@/mixins/document/bulk/Download';
import BulkSendDocumentsMixin from '@/mixins/document/bulk/Send';
import BulkSetDocumentStatusMixin from '@/mixins/document/bulk/SetStatus';
import InitBlankDocumentMixin from '@/mixins/document/init/Blank';
import InitConvertDocumentMixin from '@/mixins/document/init/Convert';
import InitCopyDocumentMixin from '@/mixins/document/init/Copy';
import InitEditDocumentMixin from '@/mixins/document/init/Edit';
import InitDocumentWithPartnerMixin from '@/mixins/document/init/Partner';
import InitDocumentTypeMixin from '@/mixins/document/init/Type';
import CreateDocumentPaymentMixin from '@/mixins/document/payments/Create';
import DeleteDocumentPaymentMixin from '@/mixins/document/payments/Delete';
import ListDocumentPaymentsMixin from '@/mixins/document/payments/List';
import PartnerMixin from '@/mixins/Partner';

@Component
export default class DocumentMixin extends Mixins<IDocumentMixin>(
  CreateDocumentMixin,
  DeleteDocumentMixin,
  DownloadDocumentMixin,
  FetchDocumentMixin,
  ListDocumentsMixin,
  ListAdvanceInvoiceDocumentsMixin,
  ListLabelsDocumentsMixin,
  NextDocumentNumberSequenceMixin,
  CheckDocumentNumberAvailabilityMixin,
  PrintDocumentMixin,
  DocumentReportMixin,
  ShareDocumentMixin,
  UpdateDocumentMixin,
  BulkDeleteDocumentsMixin,
  BulkDownloadDocumentsMixin,
  BulkSendDocumentsMixin,
  BulkSetDocumentStatusMixin,
  InitBlankDocumentMixin,
  InitConvertDocumentMixin,
  InitCopyDocumentMixin,
  InitEditDocumentMixin,
  InitDocumentWithPartnerMixin,
  InitDocumentTypeMixin,
  CreateDocumentPaymentMixin,
  DeleteDocumentPaymentMixin,
  ListDocumentPaymentsMixin,
) {
  // Fetch documents - use ListDocumentsMixin
  @Loading()
  @CatchError()
  public async listDocuments(companyUUID: string = CompanyModule.getCompanyUUID): Promise<void> {
    const { data, meta } = await this.ListDocumentsMixin(companyUUID);

    await PageModule.SET_PAGINATION(meta);
    await DocumentModule.SET_DOCUMENT_LIST(data);
  }

  // Fetch advanced invoices - use ListAdvanceInvoiceDocumentsMixin
  public async listAdvanceInvoices(
    params: IAdvanceInvoiceLookupParams,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<IAdvanceInvoiceList[]> {
    return await this.ListAdvanceInvoiceDocumentsMixin(params, companyUUID);
  }

  // Fetch document labels - use ListLabelsDocumentsMixin
  public async listDocumentLabels(
    params: IUrlParams,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<string[]> {
    return await this.ListLabelsDocumentsMixin(params, companyUUID);
  }

  // Fetch single document - use DocumentSingleMixin
  @Loading()
  @CatchError()
  public async fetchDocument(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<IDocument> {
    return await this.FetchDocumentMixin(documentUUID, companyUUID);
  }

  // Create new document - use CreateDocumentMixin
  @Loading()
  @CatchError()
  public async createDocument(companyUUID: string = CompanyModule.getCompanyUUID): Promise<void> {
    const createdDocument = await this.CreateDocumentMixin(companyUUID);

    await this.$notifier({
      message: this.trans('notify.success.record_created', 'Ieraksts veiksmīgi izveidots'),
      state: AppState.SUCCESS,
    });

    // Refetch company data if user has set update sender globally
    if (DocumentModule.forceUpdateCompany) {
      await CompanyModule.SET_COMPANY();
    }

    await this.$router.push({
      name: DocumentRouteName.EDIT,
      params: { document: createdDocument.type, uuid: createdDocument.uuid },
    });
  }

  // Update existing document - use UpdateDocumentMixin
  @Loading()
  @CatchError()
  public async updateDocument(companyUUID: string = CompanyModule.getCompanyUUID): Promise<void> {
    const updatedDocument = await this.UpdateDocumentMixin(companyUUID);

    await this.$notifier({
      message: this.trans('notify.success.record_updated', 'Ieraksts veiksmīgi izlabots'),
      state: AppState.SUCCESS,
    });

    // Refetch company data if user has set update sender globally
    if (DocumentModule.forceUpdateCompany) {
      await CompanyModule.SET_COMPANY();
    }

    await DocumentModule.SET_DOCUMENT({
      status: updatedDocument.status,
      items: updatedDocument.items,
      logs: updatedDocument.logs,
    });
  }

  // Delete existing document - use DeleteDocumentMixin
  @Loading()
  @CatchError()
  public async deleteDocument(documentUUID: string, companyUUID: string = CompanyModule.getCompanyUUID): Promise<void> {
    await this.DeleteDocumentMixin(documentUUID, companyUUID);

    await this.$notifier({
      message: this.trans('notify.success.record_deleted', 'Ieraksts veiksmīgi izdzēsts'),
      state: AppState.SUCCESS,
    });
  }

  // Download document in memory - use DownloadDocumentInMemoryMixin from DownloadDocumentMixin
  @Loading()
  @CatchError()
  public async downloadDocumentInMemory(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<File> {
    const fileType = DownloadDocument.PDF;
    return await this.DownloadDocumentInMemoryMixin(documentUUID, fileType, companyUUID);
  }

  // Download document - use DownloadDocumentInBrowserMixin from DownloadDocumentMixin
  @Loading()
  @CatchError()
  public async downloadDocumentInBrowser(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    const fileType = DownloadDocument.PDF;
    return await this.DownloadDocumentInBrowserMixin(documentUUID, fileType, companyUUID);
  }

  // Print document - use PrintDocumentMixin
  @Loading()
  @CatchError()
  public async printDocument(documentUUID: string, companyUUID: string = CompanyModule.getCompanyUUID): Promise<void> {
    const fileType = DownloadDocument.PDF;
    return await this.PrintDocumentMixin(documentUUID, fileType, companyUUID);
  }

  // Generate document report - use DocumentReportMixin
  @Loading()
  @LoadingBulk()
  @CatchError()
  public async documentReport(
    payload: IDocumentReportForm | IDocumentBulkReportForm,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    const available: FileType[] = Object.values(FileType);
    if (!available.includes(payload.format)) {
      await this.$notifier({
        message: this.trans(
          'notify.error.incorrect_format.report',
          "Izvēlēts neatļauts faila formāts ':format', pieejamie formāti :available",
          {
            available,
            format: [FileType.ZALKTIS, FileType.JUMIS].includes(payload.format) ? 'xml' : payload.format,
          },
        ),
        state: AppState.ERROR,
      });
      return;
    }
    return await this.DocumentReportMixin(payload, companyUUID);
  }

  // Generate document e-invoice - use DocumentReportMixin
  @Loading()
  @LoadingBulk()
  @CatchError()
  public async documentEinvoice(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    const fileType = DownloadDocument.UBL;
    return await this.DownloadDocumentInBrowserMixin(documentUUID, fileType, companyUUID);
  }

  // Share document - use ShareDocumentMixin
  @Loading()
  @CatchError()
  public async shareDocument(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<string> {
    return await this.ShareDocumentMixin(documentUUID, companyUUID);
  }

  // Fetch next document number iterator - use NextDocumentNumberSequenceMixin
  @Loading()
  @CatchError()
  public async nextDocumentNumberSequence(
    documentNumber: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<number> {
    return await this.NextDocumentNumberSequenceMixin(documentNumber, companyUUID);
  }

  // Check document number availability - use CheckDocumentNumberAvailabilityMixin
  @Loading()
  @CatchError()
  public async checkDocumentNumberAvailability(
    documentNumber: string,
    documentIterator: number,
    documentDate: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<number> {
    const recordNumber = this.previewDocumentRecordNumber(documentNumber, documentIterator, documentDate);
    const available = await this.CheckDocumentNumberAvailabilityMixin(recordNumber, companyUUID);
    return available ? documentIterator : await this.nextDocumentNumberSequence(documentNumber);
  }

  // Bulk delete documents - use BulkDeleteDocumentsMixin
  @Loading()
  @LoadingBulk()
  @CatchError()
  public async bulkDeleteDocuments(
    documents: IDocumentList[],
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    await this.BulkDeleteDocumentsMixin(documents, companyUUID);

    await this.$notifier({
      message:
        documents.length > 1
          ? this.trans('notify.success.record_deleted.plural', 'Ieraksti veiksmīgi izdzēsti')
          : this.trans('notify.success.record_deleted', 'Ieraksts veiksmīgi izdzēsts'),
      state: AppState.SUCCESS,
    });
  }

  // Bulk download documents - use BulkDownloadDocumentsMixin
  @LoadingBulk()
  @CatchError()
  public async bulkDownloadDocuments(
    documents: IDocumentList[],
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    return await this.BulkDownloadDocumentsMixin(documents, companyUUID);
  }

  // Bulk send documents - use BulkSendDocumentsMixin
  @Loading()
  @LoadingBulk()
  @CatchError()
  public async bulkSendDocuments(
    documents: ISendDocumentForm,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    const documentUUIDs = await this.BulkSendDocumentsMixin(documents, companyUUID);

    await DocumentModule.SET_DOCUMENT_STATUS({ documentUUIDs, status: DocumentStatus.SENT });
    await DocumentModule.SET_DOCUMENT({ status: DocumentStatus.SENT });

    await this.$notifier({
      message: this.trans('notify.success.email_sent', 'E-pasts veiksmīgi nosūtīts'),
      state: AppState.SUCCESS,
    });
  }

  // Bulk download documents - use BulkDownloadDocumentsMixin
  @Loading()
  @LoadingBulk()
  @CatchError()
  public async bulkSetDocumentStatus(
    documents: IDocumentList[],
    status: DocumentStatus,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    const documentUUIDs = await this.BulkSetDocumentStatusMixin(documents, status, companyUUID);
    await DocumentModule.SET_DOCUMENT_STATUS({ documentUUIDs, status });
    await this.$notifier({
      message: this.trans('notify.success.record_updated', 'Ieraksts veiksmīgi izlabots'),
      state: AppState.SUCCESS,
    });
  }

  // Init blank document - use InitBlankDocumentMixin
  @Loading()
  @CatchError()
  public async initBlankDocument(type: DocumentType): Promise<void> {
    const document = await this.InitBlankDocumentMixin(type);
    document.number_iterator = await this.nextDocumentNumberSequence(document.number_format);
    await DocumentModule.SET_DOCUMENT(document);
    await DocumentModule.SET_DOCUMENT_TOTALS();
  }

  // Init copy document - use InitCopyDocumentMixin
  @Loading()
  @CatchError()
  public async initCopyDocument(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    const fetchedDocument = await this.fetchDocument(documentUUID, companyUUID);
    const document = await this.InitCopyDocumentMixin(fetchedDocument);
    document.number_iterator = await this.nextDocumentNumberSequence(document.number_format);
    await DocumentModule.SET_DOCUMENT(document);
    await DocumentModule.SET_DOCUMENT_TOTALS();
  }

  // Init edit document - use InitEditDocumentMixin
  @Loading()
  @CatchError()
  public async initEditDocument(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    const fetchedDocument = await this.fetchDocument(documentUUID, companyUUID);
    const document = await this.InitEditDocumentMixin(fetchedDocument);
    await DocumentModule.SET_DOCUMENT(document);
    await DocumentModule.SET_DOCUMENT_TOTALS();
  }

  // Init document with partner - use InitDocumentWithPartnerMixin
  @Loading()
  @CatchError()
  public async initDocumentWithPartner(
    type: DocumentType,
    partnerUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    const blankDocuemnt = await this.InitBlankDocumentMixin(type);
    const partner = await new PartnerMixin().fetchPartner(partnerUUID, companyUUID);
    const document = await this.InitDocumentWithPartnerMixin(blankDocuemnt, partner);
    document.number_iterator = await this.nextDocumentNumberSequence(document.number_format);
    await DocumentModule.SET_DOCUMENT(document);
    await DocumentModule.SET_DOCUMENT_TOTALS();
  }

  // Init convert document - use InitConvertDocumentMixin
  @Loading()
  @CatchError()
  public async initConvertDocument(
    type: DocumentType,
    documentUUID: string,
    isFinal: boolean = false,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    const fetchedDocument = await this.fetchDocument(documentUUID, companyUUID);

    const convertTypes = [
      DocumentType.INVOICE,
      DocumentType.WAYBILL,
      DocumentType.CREDIT_NOTE,
      DocumentType.ADVANCE_INVOICE,
    ];
    const convertTo = convertTypes.includes(type) ? type : DocumentType.INVOICE;

    const document = await this.InitConvertDocumentMixin(convertTo, fetchedDocument, isFinal);
    document.number_iterator = await this.nextDocumentNumberSequence(document.number_format);
    await DocumentModule.SET_DOCUMENT(document);
    await DocumentModule.SET_DOCUMENT_TOTALS();
  }

  // Init type document - use InitDocumentTypeMixin
  @Loading()
  @CatchError()
  public async initDocumentType(): Promise<void> {
    const document = await this.InitDocumentTypeMixin();
    document.number_iterator = await this.nextDocumentNumberSequence(document.number_format);
    await DocumentModule.SET_DOCUMENT(document);
    await DocumentModule.SET_DOCUMENT_TOTALS();
  }

  // List document payments - use ListDocumentPaymentsMixin
  @Loading()
  public async listDocumentPayments(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<IDocumentPayment[]> {
    return await this.ListDocumentPaymentsMixin(documentUUID, companyUUID);
  }

  // Create document payment - use CreateDocumentPaymentMixin
  @Loading()
  @CatchError()
  public async createDocumentPayment(
    documentUUID: string,
    payload: IDocumentPaymentForm,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<IDocumentPayment> {
    const payment = await this.CreateDocumentPaymentMixin(documentUUID, payload, companyUUID);

    await this.$notifier({
      message: this.trans('notify.success.record_created', 'Ieraksts veiksmīgi izveidots'),
      state: AppState.SUCCESS,
    });

    return payment;
  }

  // Delete document payment - use DeleteDocumentPaymentMixin
  @Loading()
  @CatchError(CatchRule.NOT_FOUND)
  public async deleteDocumentPayment(
    documentUUID: string,
    paymentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    await this.DeleteDocumentPaymentMixin(documentUUID, paymentUUID, companyUUID);

    await this.$notifier({
      message: this.trans('notify.success.record_deleted', 'Ieraksts veiksmīgi izdzēsts'),
      state: AppState.SUCCESS,
    });
  }
}
