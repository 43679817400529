import cloneDeep from 'lodash-es/cloneDeep';
import _Vue from 'vue';
import DEFAULT_STOCK_FORM from '@/constants/mocks/forms/Stock';
import DOCUMENT_NUMBER, { DOCUMENT_NUMBER_SEQUENCE } from '@/constants/regex/DocumentNumber';
import DEFAULT_COMPANY from '@/constants/mocks/company/Company';
import DEFAULT_DOCUMENT_FORM from '@/constants/mocks/forms/Document';
import DocumentType from '@/enums/config/document/Type';
import RecurringDocumentType from '@/enums/config/document/RecurringType';
import DocumentStatus from '@/enums/config/document/Status';
import DateFormat from '@/enums/config/DateFormat';
import type IBank from '@/interfaces/bank/IBank';
import type { IDocumentPreference } from '@/interfaces/company/IDocumentSettings';
import type ISender from '@/interfaces/document/ISender';
import type IRecipient from '@/interfaces/document/IRecipient';
import type ICompany from '@/interfaces/company/ICompany';
import type IPartner from '@/interfaces/partner/IPartner';
import type IStock from '@/interfaces/stock/IStock';
import BankModule from '@/store/modules/Bank';
import CompanyModule from '@/store/modules/Company';
import StockMixin from '@/mixins/Stock';

export default function DocumentPlugin(Vue: typeof _Vue): void {
  Vue.prototype.previewDocumentRecordNumber = (
    docNum: string,
    nextSequenceNum: number = 1,
    docDate: string = '',
    dateFormat = DateFormat.LARAVEL,
  ): string => {
    docDate = Vue.prototype.parseFormatToFormat(
      docDate || Vue.prototype.parseNowToFormat(dateFormat),
      dateFormat,
      DateFormat.DATABASE,
    );

    const dateSplit: string[] = docDate.split('-');
    const year: string = dateSplit[0];
    const month: string = Number(dateSplit[1]).toString();
    const day: string = Number(dateSplit[2]).toString();

    // This is very complicate regex replace, but it works
    // It looks for all possible combinations of keys and replaces them with correct values
    docNum = docNum.replace(/\{(N(Y|M|D)?|Y|M|D)(\d*)?\}/gi, (match, key, _, minLen) => {
      // Key is set to take only first character
      // Because key could 1 to 2 letters long, for example NY, NM or ND
      // Since we don't care about second letter we take only first one
      switch (key.charAt(0)) {
        case 'D':
          return minLen ? day.padStart(2, '0') : day;
        case 'M':
          return minLen ? month.padStart(2, '0') : month;
        case 'Y':
          return minLen ? year.slice(-2) : year;
        case 'N':
          return minLen ? nextSequenceNum.toString().padStart(minLen, '0') : nextSequenceNum.toString();
        default:
          return match;
      }
    });

    return docNum;
  };

  Vue.prototype.formatDocumentRecordNumber = (docNum: string): string => {
    const numberSequence: string = '{N}';

    // Change invalid date and sequence keys to valid
    docNum = docNum.replace(/\{(N[YMD]?[2-9]?).*?\}/gi, '{$1}');
    docNum = docNum.replace(/\{([YMD]2?).*?\}/gi, '{$1}');

    // Change key values to uppercase if they are valid
    docNum = docNum.replace(DOCUMENT_NUMBER, (match) => match.toUpperCase());

    let count = 0;
    // Remove all redundant sequence numbers except the first one
    docNum = docNum.replace(DOCUMENT_NUMBER_SEQUENCE, (match) => (++count > 1 ? '' : match));
    // Add sequence number if it doesn't exist
    docNum += count ? '' : numberSequence;

    return docNum;
  };

  Vue.prototype.retrieveNumberSequenceLeadingZero = (docNum: string): number => {
    const keys: RegExpMatchArray | null = docNum.match(DOCUMENT_NUMBER_SEQUENCE);

    return Number(keys?.[0]?.match(/\d+/i)?.[0] || 1);
  };

  Vue.prototype.retrieveNumberSequencePeriodReset = (docNum: string): string => {
    const keys: RegExpMatchArray | null = docNum.match(DOCUMENT_NUMBER_SEQUENCE);

    return (keys?.[0].match(/N(Y|M|D)?\d*/i)?.[1] ?? '').toUpperCase();
  };

  Vue.prototype.retrieveSenderDetails = async (type: DocumentType | RecurringDocumentType): Promise<ISender> => {
    const company: ICompany = CompanyModule.getCompany || DEFAULT_COMPANY;
    const documentPreferences: IDocumentPreference = await CompanyModule.GET_DOCUMENT_PREFERENCES(type);
    const paymentMethods: IBank[] = BankModule.getBanks;

    const sender: ISender = {
      ...DEFAULT_DOCUMENT_FORM.sender,
      name: company.name,
      registration_number: company.registration_number,
      vat_registration_number: company.vat_registration_number,

      payment_methods: cloneDeep(paymentMethods),

      email: company.email,
      address: company.address,
      country: company.country,
      website: company.website,

      phone_prefix: company.phone_prefix || DEFAULT_COMPANY.phone_prefix,
      phone_number: company.phone_number,

      notes: documentPreferences.default_supplier_notes,
    };

    return cloneDeep(sender);
  };

  Vue.prototype.retrieveNumberFormat = async (type: DocumentType | RecurringDocumentType): Promise<string> => {
    const documentPreferences: IDocumentPreference = await CompanyModule.GET_DOCUMENT_PREFERENCES(type);
    return documentPreferences.number_format;
  };

  // Helper functions

  Vue.prototype.removePartiallyPaidStatus = (type: DocumentType, status: DocumentStatus): boolean => {
    const hasPartiallyPaid = status === DocumentStatus.PARTIALLY_PAID;
    const isCreditNoteOrOffer = [DocumentType.CREDIT_NOTE, DocumentType.OFFER].includes(type);

    return !(hasPartiallyPaid && isCreditNoteOrOffer);
  };

  Vue.prototype.generateDefaultStock = (item = DEFAULT_STOCK_FORM): string => {
    const newStock = new StockMixin().composeItem(item);

    return JSON.stringify({
      name: newStock.name,
      measurement: newStock.measurement,
      quantity: newStock.quantity.toString(),
      price: newStock.price.toString(),
      discount: (newStock.discount ?? '').toString(),
      vat_percent: (newStock.vat_percent ?? '').toString(),
      price_with_vat: newStock.price_with_vat.toString(),
      total_with_vat: (newStock.total_with_vat ?? 0).toString(),
      total_without_vat: (newStock.total_without_vat ?? 0).toString(),
    });
  };

  Vue.prototype.normalizeSender = (sender: ISender): ISender => {
    const paymentMethods = sender.payment_methods.map((payment) => {
      delete payment.id;
      delete payment.uuid;
      delete payment.is_primary;

      return payment;
    });

    return { ...sender, payment_methods: paymentMethods };
  };

  Vue.prototype.normalizeRecipient = (recipient: IRecipient | IPartner): IRecipient => {
    const paymentMethods = recipient.payment_methods.map((payment) => {
      delete payment.id;
      delete payment.uuid;
      delete payment.name;
      delete payment.is_primary;

      return payment;
    });

    return {
      ...recipient,
      payment_methods: paymentMethods,
      vat_tax_payer: !!recipient.vat_registration_number,
      preferences: {
        ...recipient.preferences,
        late_fee_percent: recipient.preferences.late_fee_percent || 0,
        default_due_days: recipient.preferences.default_due_days || 0,
      },
      update_globally: false,
      created_at: '',
    };
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    previewDocumentRecordNumber(docNum: string, nextSequenceNum?: number, date?: string, format?: DateFormat): string;
    formatDocumentRecordNumber(docNum: string): string;
    retrieveNumberSequenceLeadingZero(docNum: string): number;
    retrieveNumberSequencePeriodReset(docNum: string): string;
    retrieveSenderDetails(type: DocumentType | RecurringDocumentType): Promise<ISender>;
    retrieveNumberFormat(type: DocumentType | RecurringDocumentType): Promise<string>;
    // Helper functions
    removePartiallyPaidStatus(type: DocumentType, status: DocumentStatus): boolean;
    generateDefaultStock(item?: IStock): string;
    normalizeSender(sender: ISender): ISender;
    normalizeRecipient(recipient: IRecipient | IPartner): IRecipient;
  }
}
