<template>
  <div class="pale w-screen min-h-screen flex flex-col justify-center items-center">
    <div class="block w-full space-y-8" style="max-width: 50%">
      <div>DOWNLAOD HERE</div>
      <button @click="generatePDF">Click Here</button>
    </div>
  </div>
</template>

<script lang="ts">
  import Axios from 'axios';
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class DebugPage extends Vue {
    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/
    /*****         helpers        *****/
    /*****      vue lifecycle     *****/

    public async generatePDF(): Promise<void> {
      const response = await Axios.post(
        'http://microservices.mondayspace.test/einvoice/generate/pdf',
        {
          params: { locale: 'LV' },
        },
        {
          responseType: 'blob', // Set the response type to 'blob' for binary data
        },
      );

      // const response = await Axios.post(
      //   'http://pdf.mondayspace.test/generate/single',
      //   {
      //     contents: '<h1>Hello!</h1>',
      //     options: {
      //       format: 'A4',
      //       displayHeaderFooter: true,
      //       headerTemplate:
      //         '<p style="color: red; font-size: 16px;">Page <span class="pageNumber"></span> of <span class="totalPages"></span></p>',
      //       footerTemplate:
      //         '<p style="color: red; font-size: 16px;">Page <span class="pageNumber"></span> of <span class="totalPages"></span></p>',
      //       margin: {
      //         top: 0,
      //       },
      //     },
      //   },
      //   {
      //     headers: {
      //       // 'Accept': 'application/json',
      //       // 'Content-Type': 'application/json',
      //       // 'Accept-Encoding': 'gzip, deflate, br, zstd',
      //       Authorization: `Bearer SuperSecretKey`,
      //       'App-Locale': 'LV',
      //     },
      //     responseType: 'blob', // Set response type to arraybuffer
      //   },
      // );

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const file: File = new File([blob], 'documentName', { type: 'application/pdf' });

      console.log('FILE', file);

      // Create a URL for the Blob object
      const url: string = window.URL.createObjectURL(file);

      // Create a new anchor element and set the download attribute to the document name
      const link: HTMLAnchorElement = document.createElement('a');
      link.href = url;
      link.download = file.name;
      link.style.display = 'none';

      // Append the anchor element to the document body and click it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the URL to free up memory
      URL.revokeObjectURL(url);
    }

    // public async DownloadDocumentInBrowserMixin(
    //   documentUUID: string,
    //   fileType = DownloadDocument.PDF,
    //   companyUUID: string = CompanyModule.getCompanyUUID,
    // ): Promise<void> {
    //   const file: File = await this.DownloadDocumentInMemoryMixin(documentUUID, fileType, companyUUID);

    //   // Create a URL for the Blob object
    //   const url: string = window.URL.createObjectURL(file);

    //   // Create a new anchor element and set the download attribute to the document name
    //   const link: HTMLAnchorElement = document.createElement('a');
    //   link.href = url;
    //   link.download = file.name;
    //   link.style.display = 'none';

    //   // Append the anchor element to the document body and click it
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);

    //   // Revoke the URL to free up memory
    //   URL.revokeObjectURL(url);
    // }
  }
</script>
