import { Component, Vue } from 'vue-property-decorator';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';

@Component
export default class CheckDocumentNumberAvailabilityMixin extends Vue {
  public async CheckDocumentNumberAvailabilityMixin(
    recordNumber: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<boolean> {
    return (await DocumentRepository.checkRecordNumber(companyUUID, recordNumber)).data.available;
  }
}
